import React from "react";
import HalfNHalf from "../assets/halfNHalf.jpeg";
import caliLover from "../assets/caliLover.jpeg";
import RollyMolly from "../assets/rollyMolly.jpeg";
import KaraageFeast from "../assets/karaageFeast.jpg";
import "./PlatterBox.css";

const menuItems = {
  "Half n Half": {
    src: HalfNHalf,
    text: "Half n Half",
    price: "$85",
    items: [
      "Popping Tobiko (4 Pcs)",
      "Spicy Karaage (4 Pcs)",
      "Honey Chicken (4 Pcs)",
      "Dreamy Tuna Mayo (4 Pcs)",
      "Teriyaki Chicken Roll (5 Pcs)",
      "Salmon Avocado Roll (5 Pcs)",
      "Vegetable Roll (5 Pcs)",
      "Katsu Chicken Roll (5 Pcs)",
    ],
    total: "Total 36 Pcs",
  },
  "Cali Lover": {
    src: caliLover,
    text: "Cali Lover",
    price: "$99",
    items: [
      "Veggie Delight (4 Pcs)",
      "Fresh salmon w/ Crab (4 Pcs)",
      "Dreamy Tuna Mayo (4 Pcs)",
      "Spicy Karaage (4 Pcs)",
      "Supreme Black Tobiko (4 Pcs)",
      "Teriyaki Salmon (4 Pcs)",
      "Honey Chicken (4 Pcs)",
      "Popping Tobiko (4 Pcs)",
    ],
    total: "Total 32 Pcs",
  },
  "Rolly Molly": {
    src: RollyMolly,
    text: "Rolly Molly",
    price: "$75",
    items: [
      "Vegetable Roll (5 Pcs)",
      "Pineapple Avo Cream Cheese Roll (5 Pcs)",
      "Prawn Roll (5 Pcs)",
      "Tuna Roll (5 Pcs)",
      "Salmon Avocado Roll (5 Pcs)",
      "Salmon Avo Cream Cheese Roll (5 Pcs)",
      "Katsu Chicken Roll (5 Pcs)",
      "Teriyaki Chicken Roll (5 Pcs)",
    ],
    total: "Total 40 Pcs",
  },
  "Karaage Feast": {
    src: KaraageFeast,
    text: "Karaage Feast",
    price: "$95",
    items: [
      "Chicken Karaage Skewers (15)",
      "Spicy Chicken Karaage Skewers (15)",
    ],
    total: "Total 30 Skewers",
  },
};

const MenuItem = ({ item }) => {
  const { src, text, price, items, total } = menuItems[item];

  return (
    <div className="menu-item">
      <img src={src} alt={text} />
      <p style={{ fontWeight: "bold", fontSize: 28 }}>{`${text} - ${price}`}</p>
      <p style={{ fontWeight: "bold", fontSize: 24 }}>{total}</p>
      <ul>
        {items.map((menuItem, index) => (
          <li key={index}>{menuItem}</li>
        ))}
      </ul>
      {/* <p style={{ fontWeight: "bold", fontSize: 26 }}>{total}</p> */}
    </div>
  );
};

const PlatterBox = () => {
  return (
    <div id="platterBox" className="container">
      <div
        style={{
          fontWeight: "bold",
          color: "#000",
          paddingBlock: "2%",
          fontSize: 50,
          textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
        }}
      >
        Platter Box
      </div>
      <div className="innerContainer">
        {Object.keys(menuItems).map((item, index) => (
          <div key={index}>
            <MenuItem item={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlatterBox;
