import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Header from "./components/Header";
import Home from "./screens/Home";
import About from "./screens/About";
import Menu from "./screens/Menu";
import PlatterBox from "./screens/PlatterBox";
// import OrderNow from "./screens/OrderNow";
import Contact from "./screens/Contact";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Handlee",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <Home />
        <About />
        <PlatterBox />
        <Menu />
        {/* <OrderNow /> */}
        <Contact />
        <Footer />
        <ScrollToTop />
      </div>
    </ThemeProvider>
  );
}

export default App;
