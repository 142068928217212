import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../assets/10001310 - Copy.gif";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const navigationLinks = [
    { text: "Home", id: "home" },
    { text: "About", id: "about" },
    { text: "Platter Box", id: "platterBox" },
    { text: "Menu", id: "menu" },
    // { text: "Order Now", id: "orderNow" },
    { text: "Contact", id: "contact" },
  ];

  return (
    <div>
      <AppBar position="sticky" sx={{ backgroundColor: "#242424", margin: 0 }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={Logo}
              alt="Restaurant Logo"
              width="50"
              style={{ borderRadius: "50%", marginRight: "10px" }}
            />
            <Typography variant="h6" sx={{ display: "inline", marginLeft: 3 }}>
              Manna Sushi & Don
            </Typography>
          </div>
          <IconButton
            color="inherit"
            edge="end"
            onClick={toggleDrawer}
            sx={{ display: { sm: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <List sx={{ display: { xs: "none", md: "flex" } }}>
            {navigationLinks.map((link) => (
              <ListItemButton
                key={link.text}
                onClick={() => scrollToSection(link.id)}
              >
                <ListItemText primary={link.text} />
              </ListItemButton>
            ))}
          </List>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{ "& .MuiDrawer-paper": { width: "50%" } }}
      >
        <List>
          {navigationLinks.map((link) => (
            <ListItemButton
              key={link.text}
              onClick={() => {
                scrollToSection(link.id);
                toggleDrawer();
              }}
            >
              <ListItemText primary={link.text} />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default Header;
