import React from "react";
import "./About.css";
// import Banner from "../assets/387747872_18123209101314814_6562664167557571717_n.jpg";
import ImageCard from "../components/ImageCard";

function About() {
  return (
    <div id="about" className="about">
      <div className="content">
        <div className="left">
          <header>
            <h1>About Us</h1>
          </header>
          <p>
            In the mood for Japanese? Manna Sushi & Don serve Japanese food for
            your culinary pleasure. No need to cook this evening, now doesn't
            that make sense after a hard day at work. I am sure the other half
            would approve. What we recommend? Try Spicy Pork Bibimbap or Beef
            Teriyaki Bento Box.
            {/* Check out the menu, give us a ring, and your */}
            {/* order will be delivered. */}
          </p>
          <p>
            Explore our menu, give us a call for convenient pick-up orders, or
            enjoy the ease of delivery through UberEats, Menulog and Doordash - the choice
            is yours!
          </p>
        </div>
        <div className="right">
          {/* <img src={Banner} alt="About Us" width="400" height="400" /> */}
          <ImageCard />
        </div>
      </div>
    </div>
  );
}

export default About;
