// Footer.js
import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
// import YouTubeIcon from "@mui/icons-material/YouTube";
// import { FaXTwitter } from "react-icons/fa6";
import { Box } from "@mui/material";

const Footer = () => {
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          bgcolor: "#1A1A19",
          color: "white",
          padding: 3,
          paddingBottom: 3.5,
          margin: 0,
        }}
      >
        <Box
          sx={{
            my: 3,
            "& a": {
              textDecoration: "none",
              color: "white",
            },
            "& svg": {
              fontSize: "60px",
              cursor: "pointer",
              mr: 2,
            },
            "& svg:hover": {
              color: "#F6C22B",
              transform: "translateX(5px)",
              transition: "all 400ms",
            },
          }}
        >
          <a
            href="https://instagram.com/manna_sushi"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://www.facebook.com/mannasushianddon?mibextid=PzaGJu"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          {/* <a href="https://twitter.com" target="_blank" rel="noreferrer">
            <FaXTwitter />
          </a> */}
        </Box>
        {/* <Typography
          variant="h5"
          sx={{
            "@media (max-width:600px)": {
              fontSize: "1rem",
            },
          }}
        >
          Copyright © 2023 Manna Sushi & Don. All Rights Reserved.
        </Typography> */}
      </Box>
    </>
  );
};

export default Footer;
