import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  // ListItemSecondaryAction,
  Container,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import menuData from "../components/menuData";
import Banner from "../assets/pexels-farhad-ibrahimzade-5713760.jpg";

function Menu() {
  // const [expanded, setExpanded] = useState(null);

  // const handleAccordionChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : null);
  // };

  // const getRandomColor = () => {
  //   const letters = "0123456789ABCDEF";
  //   let color = "#";
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };

  const colors = ["#666666", "#FF0000", "#5F0C5A", "#5A83CE"];

  return (
    <Container
      id="menu"
      style={{
        padding: "1% auto",
        backgroundColor: "#F7F6E2",
        maxWidth: "100%",
        maxHeight: "100%",
        paddingTop: 20,
        paddingBottom: 10,
        paddingLeft: "2%",
        paddingRight: "2%",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "#fff",
          paddingBlock: "2%",
          fontSize: 50,
          textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
          backgroundImage: `url(${Banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          marginBottom: "1%",
        }}
      >
        Menu
      </Typography>
      {/* <Typography
        variant="body2"
        sx={{
          color: "red",
          marginTop: "1%",
          marginBottom: "1%",
          fontWeight: "bold",
        }}
      >
        * Everything can be made spicy on request
      </Typography> */}
      {menuData.map((category, index) => (
        <Accordion
          key={index}
          // expanded={expanded === `panel${index}`}
          // onChange={handleAccordionChange(`panel${index}`)}
          style={{
            backgroundColor: "#F2F2F2",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          {/* <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "black" }}
            >
              {category.category}
            </Typography>
          </AccordionSummary> */}
          {/* <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "black" }}
                >
                  {category.category}
                </Typography>
              </Grid>
              {category.description && (
                <Grid item>
                  <Typography variant="body2" sx={{ color: "black" }}>
                    {category.description}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </AccordionSummary> */}
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "8px",
                  fontSize: 24,
                }}
              >
                {category.category}
              </Typography>
              {category.description && (
                <Typography
                  variant="body2"
                  sx={{ color: "black", textAlign: "center", fontSize: 18 }}
                >
                  {category.description}
                </Typography>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {category.items.map((item, i) => (
                <div key={i}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "bold",
                            color: item.color || "black",
                            maxWidth: "100%",
                            fontSize: 22,
                          }}
                        >
                          {item.name}
                          {item.glutenFree && (
                            <span
                              style={{
                                marginLeft: "5px",
                                marginTop: -5,
                                backgroundColor: "black",
                                borderRadius: "50px",
                                padding: "2px",
                                color: "white",
                                fontSize: "12px",
                              }}
                            >
                              GF
                            </span>
                          )}
                          {item.canBeMadeGlutenFree && (
                            <span
                              style={{
                                marginLeft: "5px",
                                marginBottom: -5,
                                fontSize: 14,
                              }}
                            >
                              *GF available
                            </span>
                          )}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="body2"
                          sx={{
                            color: "black",
                            maxWidth: "100%",
                            fontSize: 18,
                          }}
                        >
                          {item.description}
                        </Typography>
                      }
                    />
                    {/* <ListItemSecondaryAction>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          fontSize: 22,
                        }}
                      >
                        {item.price}
                      </Typography>
                    </ListItemSecondaryAction> */}
                  </ListItem>
                  {/* {i < category.items.length - 1 && <Divider />} */}
                  {/* {i < category.items.length - 1 && (
                    <Divider sx={{ backgroundColor: getRandomColor() }} />
                  )} */}
                  {/* {i < category.items.length - 1 && (
                    <Divider
                      sx={{
                        backgroundColor: i % 2 === 0 ? "#666666" : "#6A5ACD",
                      }}
                    />
                  )} */}
                  {i < category.items.length - 1 && (
                    <Divider
                      sx={{
                        backgroundColor: colors[i % colors.length],
                      }}
                    />
                  )}
                </div>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}

export default Menu;
