import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const ImageCard = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [loading, setLoading] = useState(false);

 // Use useMemo to memoize the images array
  const images = useMemo(
    () => [
      require("../assets/ImageCard0.jpg"),
      require("../assets/ImageCard1.jpeg"),
      require("../assets/ImageCard2.jpeg"),
    ],
    []
  );

  // Memoize the switchImage function using useCallback
  const switchImage = useCallback(
    (direction) => {
      setOpacity(0);
      setLoading(true); // Set loading to true before changing the image
      setTimeout(() => {
        if (direction === "left") {
          setCurrentImage((prev) =>
            prev === 0 ? images.length - 1 : prev - 1
          );
        } else {
          setCurrentImage((prev) =>
            prev === images.length - 1 ? 0 : prev + 1
          );
        }
      }, 500);
    },
    [images]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      switchImage("right");
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentImage, switchImage]);

  const isMobile = window.innerWidth <= 768;

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false when the new image is fully loaded
    setOpacity(1); // Set opacity back to 1 for fade-in effect
  };

  const styles = {
    container: {
      textAlign: "center",
    },
    image: {
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      width: isMobile && "500px",
      height: isMobile ? "450px" : "500px",
      opacity: opacity,
      transition: "opacity 0.5s ease-in-out",
    },
    buttonContainer: {
      marginTop: "0px",
      justifyContent: "space-between",
    },
  };

  return (
    <div style={styles.container}>
      {loading && <div></div>}
      <img
        src={images[currentImage]}
        alt={`Slide ${currentImage + 1}`}
        style={styles.image}
        onLoad={handleImageLoad}
      />
      <div style={styles.buttonContainer}>
        <Button
          onClick={() => switchImage("left")}
          startIcon={<NavigateBeforeIcon />}
          style={{
            backgroundColor: "black",
            color: "white",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            marginRight: "20px",
            width: "50px",
            borderRadius: "20px",
          }}
        ></Button>
        <Button
          onClick={() => switchImage("right")}
          endIcon={<NavigateNextIcon />}
          style={{
            backgroundColor: "black",
            color: "white",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            width: "50px",
            borderRadius: "20px",
          }}
        ></Button>
      </div>
    </div>
  );
};

export default ImageCard;
