import React, { useEffect } from "react";
import "./Contact.css";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import { SiUbereats, SiDoordash } from "react-icons/si";
import { Typography, IconButton } from "@mui/material";

const Contact = () => {
  useEffect(() => {
    // Function to adjust content on window resize
    const handleResize = () => {
      const boxes = document.querySelectorAll(".box");

      boxes.forEach((box) => {
        // Check if content exceeds box height
        if (box.scrollHeight > box.clientHeight) {
          // Reduce font size
          box.style.fontSize = "80%";

          // Check if content still exceeds box height
          if (box.scrollHeight > box.clientHeight) {
            // Further reduce font size or apply additional adjustments as needed
            // You can also handle horizontal overflow if necessary
          }
        }
      });
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Call handleResize on initial page load
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div id="contact">
      <div className="contact-number">
        <h2 style={{ fontSize: 50 }}>Contact Us</h2>
        <address style={{ color: "#070709", fontSize: 30 }}>
          473 Lake Road, Auckland, 0622
        </address>
        <a href="tel:094897775" style={{ fontSize: 30 }}>
          Phone: 09 489 7775
        </a>
        <div>
          <h2>Explore Your Options and Order Now!</h2>
          <div>
            <IconButton
              href="https://www.ubereats.com/nz/store/manna-sushi-and-don/_6Gi1GyxTOm-X04hy5wlPw"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: "rgb(0, 165, 63)",
                padding: 10,
                margin: 10,
              }}
            >
              <SiUbereats size={30} />
            </IconButton>
            <IconButton
              href="https://www.menulog.co.nz/restaurants-manna-sushi-and-don"
              target="_blank"
              rel="noopener noreferrer"
              style={{ backgroundColor: "#FE7E00" }}
            >
              <FoodBankIcon fontSize="large" />
            </IconButton>
            <IconButton
              href="https://www.doordash.com/en-NZ/store/manna-sushi-and-don-auckland-25669189/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: "rgb(181, 49, 38)",
                padding: 10,
                margin: 10,
              }}
            >
              <SiDoordash size={30} />
            </IconButton>
          </div>
          <h3 style={{ textAlign: "center", marginTop: 10, color: "#070709" }}>
            Click the icons above to place your order!
          </h3>
        </div>
      </div>
      <div className="boxCont">
        <div className="box box-yellow">
          <div>
            <h2>Auckland</h2>
            <address>473 Lake Road, Auckland, 0622</address>
            <div className="contact-us-location__map-container">
              <iframe
                className="contact-us-location__map"
                src="https://maps.google.com/maps?output=embed&z=15&q=473 Lake Road, Auckland, 0622&hl=en-NZ"
                tabIndex="-1"
                title="Map"
              />
            </div>
          </div>
        </div>
        <div className="box box-purple">
          <h2 className="contact-us-opening-hours__subheading">
            {"Opening Hours"}
          </h2>
          <table className="contact-us-opening-hours__weekly-schedule">
            <tbody>
              <tr className="contact-us-opening-hours__daily-schedule">
                <th className="contact-us-opening-hours__day">Monday</th>
                <td className="contact-us-opening-hours__times">
                  10:00 AM - 7:00 PM
                </td>
              </tr>
              <tr className="contact-us-opening-hours__daily-schedule">
                <th className="contact-us-opening-hours__day">Tuesday</th>
                <td className="contact-us-opening-hours__times">
                  10:00 AM - 7:00 PM
                </td>
              </tr>
              <tr className="contact-us-opening-hours__daily-schedule">
                <th className="contact-us-opening-hours__day">Wednesday</th>
                <td className="contact-us-opening-hours__times">
                  10:00 AM - 7:00 PM
                </td>
              </tr>
              <tr className="contact-us-opening-hours__daily-schedule">
                <th className="contact-us-opening-hours__day">Thursday</th>
                <td className="contact-us-opening-hours__times">
                  10:00 AM - 8:00 PM
                </td>
              </tr>
              <tr className="contact-us-opening-hours__daily-schedule">
                <th className="contact-us-opening-hours__day">Friday</th>
                <td className="contact-us-opening-hours__times">
                  10:00 AM - 8:00 PM
                </td>
              </tr>
              <tr className="contact-us-opening-hours__daily-schedule">
                <th className="contact-us-opening-hours__day">Saturday</th>
                <td className="contact-us-opening-hours__times">
                  10:30 AM - 8:00 PM
                </td>
              </tr>
              <tr className="contact-us-opening-hours__daily-schedule">
                <th className="contact-us-opening-hours__day">Sunday</th>
                <td className="contact-us-opening-hours__times">Closed</td>
              </tr>
            </tbody>
          </table>
          <p className="contact-us-opening-hours__disclaimer">
            Delivery times may vary
          </p>
        </div>
        <div className="box box-grey">
          <h2>We deliver to:</h2>
          <p
            style={{
              height: "50%",
              fontSize: 16,
              marginRight: 10,
              marginLeft: 10,
            }}
          >
            0620 Castor Bay, 0620 Milford, 0622 Bayswater, 0622 Belmont, 0622
            Hauraki, 0622 Takapuna, 0624 Devonport, 0624 Narrow Neck, 0624
            Stanley Point, 0626 Beach Haven, 0626 Birkdale, 0626 Chatswood, 0627
            Birkenhead, 0627 Forrest Hill, 0627 Northcote, 0627 Northcote Point,
            0629 Bayview, 0629 Glenfield, 0629 Hillcrest, 0629 Wairau Valley,
            0630 Campbells Bay, 0630 Mairangi Bay, 0630 Murrays Bay, 0632 Albany
            Heights, 0632 Browns Bay, 0632 Fairview Heights, 0632 Greenhithe,
            0632 Oteha, 0632 Pinehill, 0632 Rosedale, 0632 Schnapper Rock, 0632
            Sunnynook, 0632 Totara Vale, 0632 Unsworth Heights, 0632 Windsor
            Park, 0793 Albany, 0794 Redvale, 0993 Dairy Flat
          </p>
          <div>
            <Typography
              variant="body2"
              style={{ marginLeft: "5px", fontWeight: "bold" }}
            >
              Click to order on Uber Eats, Menulog and Doordash!
            </Typography>
            <IconButton
              href="https://www.ubereats.com/nz/store/manna-sushi-and-don/_6Gi1GyxTOm-X04hy5wlPw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiUbereats size={30} />
            </IconButton>
            <IconButton
              href="https://www.menulog.co.nz/restaurants-manna-sushi-and-don"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FoodBankIcon fontSize="large" />
            </IconButton>
            <IconButton
              href="https://www.doordash.com/en-NZ/store/manna-sushi-and-don-auckland-25669189/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiDoordash size={30} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
