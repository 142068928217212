import React from "react";
import "./Home.css";

function Home() {
  // const scrollToOrderNow = () => {
  //   const orderNowSection = document.getElementById("orderNow");
  //   orderNowSection.scrollIntoView({ behavior: "smooth", block: "start" });
  // };

  return (
    <div id="home" className="home">
      <div className="left_home">
        <header className="home-header">
          <h1>Welcome to Manna Sushi & Don</h1>
        </header>
        <p className="home-paragraph">Savor the Finest Sushi in Auckland</p>
        <p className="home-paragraph">Where Flavor Meets Artistry!</p>
        {/* <button className="order-now-btn" onClick={scrollToOrderNow}>
          Order Now
        </button> */}
        <a
          href="https://h5.yugu.co.nz/member/orderFood?id=7410&desktop="
          target="_blank"
          rel="noopener noreferrer"
          className="order-now-btn"
        >
          Order Now
        </a>
      </div>
    </div>
  );
}

export default Home;
