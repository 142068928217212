const menuData = [
  // {
  //   category: "Entree",
  //   // description: "Test",
  //   items: [
  //     {
  //       name: "Vegetable Spring Rolls - 3 Pieces",
  //       price: "$4.50",
  //     },
  //     {
  //       name: "Spicy Chicken Karaage Skewer - 2 Skewers",
  //       price: "$7.50",
  //     },
  //     {
  //       name: "Seaweed Salad",
  //       price: "$8.90",
  //     },
  //     {
  //       name: "Prawn Tempura - 2 Pieces",
  //       price: "$5.90",
  //     },
  //     {
  //       name: "Takoyaki - 4 Pieces",
  //       price: "$6.90",
  //     },
  //     {
  //       name: "Chicken Karaage Skewer - 2 Skewers",
  //       price: "$7.50",
  //     },
  //     {
  //       name: "Vegetable Dumplings - 3 Pieces",
  //       // description: "Vegetarian dumplings (3 pieces).",
  //       price: "$4.50",
  //     },
  //   ],
  // },
  {
    category: "Bento Box",
    description:
      "Served with Sushi, Salmon Nigiri, Crumbed Prawn, Dumpling, Salad, Edamame, Rice, Miso Soup",
    items: [
      {
        name: "Teriyaki Chicken Bento Box",
        // description:
        //   "Stir fried chicken teriyaki, sushi, salmon nigiri, prawn tempura, dumpling, edamame, salad and rice.",
        price: "$20.90",
      },
      {
        name: "Teriyaki Beef Bento Box",
        // description:
        //   "Stir fried beef teriyaki, sushi, salmon nigiri, prawn tempura, dumpling, edamame, salad and rice.",
        price: "$21.90",
      },
      {
        name: "Spicy Pork Bento Box",
        // description:
        //   "Stir fried spicy pork, sushi, salmon nigiri, prawn tempura, dumpling, edamame, salad and rice.",
        price: "$21.90",
        color: "red",
      },
      {
        name: "Chicken Katsu Bento Box",
        // description:
        //   "Chicken katsu, sushi, salmon nigiri, prawn tempura, dumpling, edamame, salad and rice.",
        price: "$21.90",
      },
      {
        name: "Pork Katsu Bento Box",
        // description:
        //   "Pork katsu, sushi, salmon nigiri, prawn tempura, dumpling, edamame, salad and rice.",
        price: "$21.90",
      },
      {
        name: "Chicken Karaage Bento Box",
        // description:
        //   "Chicken karaage, sushi, salmon nigiri, prawn tempura, dumpling, edamame, salad and rice.",
        price: "$21.90",
      },
      {
        name: "Teriyaki Tofu Bento Box",
        // description:
        //   "Stir fried tofu teriyaki, sushi, salmon nigiri, prawn tempura, dumpling, edamame, salad and rice. Comes with choice of teriyaki or sweet chili.",
        price: "$21.90",
        color: "green",
      },
      // {
      //   name: "Sweet Chili Tofu Bento Box",
      //   // description:
      //   //   "Stir fried tofu teriyaki, sushi, salmon nigiri, prawn tempura, dumpling, edamame, salad and rice. Comes with choice of teriyaki or sweet chili.",
      //   price: "$21.90",
      //   color: "green",
      // },
      {
        name: "Chicken Katsu & Karaage Combo Bento Box",
        // description:
        //   "Chicken katsu and karaage, sushi, salmon nigiri, prawn tempura, dumpling, edamame, salad and rice.",
        price: "$22.90",
      },
      {
        name: "Teriyaki Salmon Bento Box",
        // description:
        //   "Stir fried salmon teriyaki, sushi, salmon nigiri, prawn tempura, dumpling, edamame, salad and rice.",
        price: "$24.90",
      },
      {
        name: "Chicken Katsu Curry Bento Box",
        // description:
        //   "Curry chicken katsu, sushi, salmon nigiri, prawn tempura, dumpling edamame, salad and rice.",
        price: "$24.90",
      },
      {
        name: "Pork Katsu Curry Bento Box",
        // description:
        //   "Stir fried pork teriyaki, sushi, salmon nigiri, prawn tempura, dumpling edamame, salad and rice.",
        price: "$24.90",
      },
      {
        name: "Unagi Bento Box",
        // description:
        //   "Japanese eel, sushi, salmon nigiri, prawn tempura, dumpling, edamame, salad and rice.",
        price: "$29.90",
      },
    ],
  },
  {
    category: "Donburi",
    description: "Served with Rice and Salad",
    items: [
      {
        name: "Teriyaki Chicken Donburi",
        description: "Stir fried teriyaki chicken with salad on rice.",
        price: "$16.90",
      },
      {
        name: "Teriyaki Beef Donburi",
        description: "Stir fried teriyaki beef with salad on rice.",
        price: "$17.90",
      },
      {
        name: "Spicy Pork Donburi",
        description: "Stir fried BBQ pork with salad on rice.",
        price: "$17.90",
        color: "red",
      },
      {
        name: "Chicken Katsu Donburi",
        description: "Deep fried crumbed chicken cutlet with salad on rice.",
        price: "$17.90",
      },
      {
        name: "Chicken Karaage Donburi",
        description: "Fried chicken Japanese style with salad on rice.",
        price: "$17.90",
      },
      {
        name: "Pork Katsu Donburi",
        description: "Deep fried crumbed pork cutlet with salad on rice.",
        price: "$17.90",
      },
      {
        name: "Teriyaki Tofu Donburi",
        description:
          "Stir fried crispy tofu and teriyaki sauce with salad on rice.",
        price: "$17.90",
        color: "green",
      },
      // {
      //   name: "Sweet Chili Tofu Donburi",
      //   description:
      //     "Stir fried crispy tofu and sweet chili sauce with salad on rice.",
      //   price: "$17.90",
      //   color: "green",
      // },
      {
        name: "Chicken Katsu & Karaage Combo Donburi",
        description:
          "Deep fried crumbed chicken cutlet and deep fried chicken Japanese style with salad on rice.",
        price: "$18.90",
      },
      {
        name: "Chicken Katsu Curry Donburi",
        description:
          "Deep fried crumbed chicken cutlet and mild Japanese curry with salad on rice.",
        price: "$20.90",
      },
      {
        name: "Pork Katsu Curry Donburi",
        description:
          "Deep fried crumbed pork cutlet and mild Japanese curry with salad on rice.",
        price: "$20.90",
      },
      {
        name: "Teriyaki Salmon Donburi",
        description: "Stir fried teriyaki salmon with salad on rice.",
        price: "$20.90",
      },
      {
        name: "Salmon Sashimi Donburi",
        description: "New Zealand king salmon with salad on rice.",
        price: "$23.90",
        glutenFree: true,
      },
      {
        name: "Unagi Donburi",
        description: "Grilled Japanese eel with salad on rice.",
        price: "$26.90",
      },
    ],
  },
  {
    category: "Bulgogi",
    description: "Served with Rice and Cooked Vegetables",
    items: [
      {
        name: "Chicken Bulgogi",
        // description: "Stir fried BBQ chicken, vegetables and rice.",
        price: "$19.90",
        canBeMadeGlutenFree: true,
      },
      {
        name: "Beef Bulgogi",
        // description: "Stir fried BBQ beef, vegetables and rice.",
        price: "$19.90",
      },
      {
        name: "Spicy Pork Bulgogi",
        // description: "Stir fried spicy BBQ pork, vegetables and rice.",
        price: "$19.90",
        color: "red",
      },
      {
        name: "Tofu Bulgogi",
        // description: "Stir fried tofu, vegetables and rice.",
        price: "$19.90",
        color: "green",
      },
    ],
  },
  {
    category: "Bibimbap",
    description:
      "Korean Style Mixed Rice. Served with Rice, Salad, Seaweed Salad, Fried Egg and Special Spicy Sauce",
    items: [
      {
        name: "Chicken Bibimbap",
        // description: "Stir fried teriyaki chicken with salad and egg on rice.",
        price: "$20.90",
      },
      {
        name: "Beef Bibimbap",
        // description: "Stir fried BBQ beef with salad and egg on rice.",
        price: "$20.90",
      },
      {
        name: "Spicy Pork Bibimbap",
        // description: "Stir fried BBQ pork with salad and egg on rice.",
        price: "$20.90",
        color: "red",
      },
      {
        name: "Tofu Bibimbap",
        // description: "Stir fried BBQ beef with salad and egg on rice.",
        price: "$20.90",
        color: "green",
      },
    ],
  },
  // {
  //   category: "Roll and Nigiri Sushi",
  //   items: [
  //     {
  //       name: "Grilled Prawn Nigiri - 2 Pieces",
  //       price: "$6.70",
  //     },
  //     {
  //       name: "Fried Teriyaki Chicken Roll Sushi - 2 Pieces",
  //       price: "$5.40",
  //     },
  //     {
  //       name: "Avocado Nigiri (Gluten Free)",
  //       description: "Hass avocado.",
  //       price: "$2.90",
  //     },
  //     {
  //       name: "Crispy Chicken Katsu Nigiri",
  //       price: "$2.90",
  //     },
  //     {
  //       name: "Seaweed Salad Roll Sushi - 5 Pieces",
  //       description:
  //         "Seaweed salad, green salad, capsicum, cucumber, red cabbage and carrot.",
  //       price: "$10.50",
  //     },
  //     {
  //       name: "Grilled Salmon with Tobiko and Prawn Tempura - 4 Pieces",
  //       description: "Prawn tempura is a roll.",
  //       price: "$16.90",
  //     },
  //     {
  //       name: "Tuna Roll Sushi - 5 Pieces",
  //       description:
  //         "Tuna, green salad, capsicum and cucumber, red cabbage and carrot.",
  //       price: "$10.50",
  //     },
  //     {
  //       name: "Tamago Nigiri (Gluten Free)",
  //       description: "Egg.",
  //       price: "$2.70",
  //     },
  //     {
  //       name: "Ebi Nigiri (Gluten Free)",
  //       price: "$2.70",
  //     },
  //     {
  //       name: "Honey Teriyaki Chicken Roll Sushi - 4 Pieces",
  //       description: "Honey teriyaki chicken, cream cheese and avocado roll.",
  //       price: "$15.50",
  //     },
  //     {
  //       name: "Crab Roll Sushi - 5 Pieces",
  //       description:
  //         "Crab, green salad, capsicum and cucumber, red cabbage and carrot.",
  //       price: "$10.50",
  //     },
  //     {
  //       name: "Prawn Tempura Roll Sushi - 5 Pieces",
  //       description:
  //         "Prawn tempura, green salad, capsicum and cucumber, red cabbage and carrot.",
  //       price: "$10.50",
  //     },
  //     {
  //       name: "Cream Cheese and Pineapple Roll Sushi (Gluten Free) - 5 Pieces",
  //       description: "Cream cheese, pineapple and avocado.",
  //       price: "$10.50",
  //     },
  //     {
  //       name: "Grilled Salmon Nigiri - 2 Pieces",
  //       price: "$6.70",
  //     },
  //     {
  //       name: "Tuna Gunkan Nigiri",
  //       description: "Tuna salad.",
  //       price: "$2.70",
  //     },
  //     {
  //       name: "Grilled Salmon with Tobiko and Crab Roll Sushi - 4 Pieces",
  //       price: "$16.90",
  //     },
  //     {
  //       name: "Tobiko Gunkan Nigiri",
  //       description: "Flying fish roe.",
  //       price: "$3.10",
  //     },
  //     {
  //       name: "Inari Nigiri",
  //       description: "Sesame rice in a tofu pocket.",
  //       price: "$2.80",
  //     },
  //     {
  //       name: "Spicy Crispy Chicken Katsu Roll Sushi - 5 Pieces",
  //       description:
  //         "Spicy crispy chicken katsu, capsicum, cucumber, red cabbage and carrot.",
  //       price: "$10.50",
  //     },
  //     {
  //       name: "Seasoned Vegetable Fried Rice Ball Roll Sushi - 2 Pieces",
  //       price: "$5.40",
  //     },
  //     {
  //       name: "Unagi Nigiri",
  //       description: "Japanese eel.",
  //       price: "$2.90",
  //     },
  //     {
  //       name: "Fried NZ King Salmon and Avocado Roll Sushi - 2 Pieces",
  //       price: "$5.90",
  //     },
  //     {
  //       name: "Ten Ebi Nigiri",
  //       description: "Fried prawn.",
  //       price: "$2.70",
  //     },
  //     {
  //       name: "Fresh Salmon Nigiri (Gluten Free)",
  //       description: "New Zealand king salmon.",
  //       price: "$3.40",
  //     },
  //     {
  //       name: "Spicy Chicken Karaage Roll Sushi - 4 Pieces",
  //       description: "Spicy chicken karaage and cucumber.",
  //       price: "$15.50",
  //     },
  //     {
  //       name: "Teriyaki Chicken Roll Sushi - 5 Pieces",
  //       description: "Teriyaki chicken, capsicum, cucumber and carrot.",
  //       price: "$10.50",
  //     },
  //     {
  //       name: "Seaweed Gunkan Nigiri",
  //       description: "Seaweed salad.",
  //       price: "$2.70",
  //     },
  //     {
  //       name: "Salmon and Avocado Roll Sushi (Gluten Free) - 5 Pieces",
  //       description: "New Zealand king salmon and avocado.",
  //       price: "$11.50",
  //     },
  //     {
  //       name: "Crispy Chicken Katsu Roll Sushi - 5 Pieces",
  //       description:
  //         "Crispy chicken katsu, capsicum, cucumber, red cabbage and carrot.",
  //       price: "$10.50",
  //     },
  //   ],
  // },

  {
    category: "Stir Fried Noodle",
    description: "Served with Stir Fried Noodle with Vegetables",
    items: [
      {
        name: "Chicken Fried Noodle",
        // description: "Stir fried chicken and noodles with vegetables.",
        price: "$19.90",
      },
      {
        name: "Beef Fried Noodle",
        // description: "Stir fried beef and noodles with vegetables.",
        price: "$19.90",
      },
      {
        name: "Prawn Fried Noodle",
        // description: "Stir fried prawn and noodles with vegetables.",
        price: "$19.90",
      },
      {
        name: "Tofu Fried Noodle",
        // description: "Stir fried tofu and noodles with vegetables.",
        price: "$19.90",
        color: "green",
      },
    ],
  },
  {
    category: "Udon",
    description: "Served with Fish Cake* & Vegetables",
    items: [
      {
        name: "Original Udon",
        description:
          "Japanese noodles with fish cake and vegetables in clear soup.",
        price: "$16.90",
      },
      {
        name: "Veggie Tempura Udon",
        description:
          "Vegetarian tempura and Japanese noodles with vegetables in clear soup.",
        price: "$18.90",
        color: "green",
      },
      {
        name: "Tofu Udon",
        description: "Tofu and Japanese noodles with vegetables in clear soup.",
        price: "$18.90",
        color: "green",
      },
      {
        name: "Chicken Katsu Udon",
        description:
          "Deep fried crumbed chicken cutlet and Japanese noodles with fish cake and vegetables in clear soup.",
        price: "$19.90",
      },
      {
        name: "Chicken Karaage Udon",
        description:
          "Deep fried chicken Japanese style and Japanese noodles with fish cake and vegetable in clear soup.",
        price: "$19.90",
      },
      {
        name: "Crumbed Prawn Udon",
        description:
          "Two pieces of deep fried crumbed prawn and Japanese noodles with fish cake and vegetable in clear soup.",
        price: "$19.90",
      },
    ],
  },
  {
    category: "Ramen",
    description: "Served with Vegetables",
    items: [
      {
        name: "Beef Ramen",
        description: "Beef and Japanese ramen with vegetable in soup.",
        price: "$19.90",
      },
      {
        name: "Seafood Ramen",
        description: "Shrimp, squid and fish cake with vegetable in soup.",
        price: "$19.90",
      },
      {
        name: "Chicken Katsu Ramen",
        description:
          "Deep fried crumbed chicken cutlet and Japanese ramen with vegetable in soup.",
        price: "$19.90",
      },
      {
        name: "Chicken Karaage Ramen",
        description:
          "Deep fried chicken Japanese style and Japanese ramen with vegetable in soup.",
        price: "$19.90",
      },
      {
        name: "Crumbed Prawn Ramen",
        description:
          "Two pieces of deep fried crumbed prawn and Japanese ramen with vegetable in soup.",
        price: "$19.90",
      },
    ],
  },
  {
    category: "Salad",
    description: "Fresh Salad, Seaweed Salad, Avocado & Special Sauce",
    items: [
      {
        name: "Teriyaki Chicken Salad",
        description: "Stir fried chicken teriyaki with salad.",
        price: "$19.90",
      },
      {
        name: "Teriyaki Beef Salad",
        description: "Stir fried beef teriyaki with salad.",
        price: "$19.90",
      },
      {
        name: "Teriyaki Tofu Salad",
        description: "Stir fried tofu teriyaki with salad.",
        price: "$19.90",
        color: "green",
      },
      {
        name: "Chicken Katsu Salad",
        description: "Deep fried crumbed chicken cutlet with salad.",
        price: "$20.90",
      },
      {
        name: "Chicken Karaage Salad",
        description: "Deep fried chicken Japanese style with salad.",
        price: "$20.90",
      },
      {
        name: "Pork Katsu Salad",
        description: "Deep fried crumbed pork cutlet with salad.",
        price: "$20.90",
      },
      {
        name: "Chicken Katsu & Karaage Combo Salad",
        description:
          "Deep fried crumbed chicken cutlet and deep fried chicken Japanese style with salad.",
        price: "$21.90",
      },
    ],
  },
  {
    category: "Sides",
    items: [
      {
        name: "Rice",
        // description: "Deep fried crumbed pork cutlet with salad.",
        price: "$3.90",
      },
      {
        name: "Miso Soup",
        // description: "Deep fried spicy chicken Japanese style with salad.",
        price: "$2.90",
      },
      {
        name: "Karaage Skewers (2 Skewers)",
        // description: "Deep fried crumbed pork cutlet with salad.",
        price: "$5.90",
      },
      { name: "Cheese Bombs (2 Bombs)", price: "$5.90" },
      {
        name: "Veggie Spring Rolls (6 Pieces)",
        // description: "Deep fried spicy chicken Japanese style with salad.",
        price: "$3.90",
      },
      {
        name: "Veggie Dumplings (6 Pieces)",
        // description: "Deep fried crumbed pork cutlet with salad.",
        price: "$3.90",
      },
      {
        name: "Takoyaki (6 Pieces)",
        // description: "Deep fried spicy chicken Japanese style with salad.",
        price: "$5.90",
      },
      {
        name: "Crumbed Prawn (2 Pieces)",
        // description: "Deep fried spicy chicken Japanese style with salad.",
        price: "$5.90",
      },
      {
        name: "Edamame",
        // description: "Deep fried crumbed pork cutlet with salad.",
        price: "$5.90",
      },
      {
        name: "Seasweed Salad",
        // description: "Deep fried spicy chicken Japanese style with salad.",
        price: "$7.90",
      },
      {
        name: "Green Salad",
        // description: "Deep fried crumbed pork cutlet with salad.",
        price: "$6.90",
      },
    ],
  },
  // {
  //   category: "Beverages",
  //   items: [
  //     {
  //       name: "L & P 330ml Can",
  //       price: "$2.90",
  //     },
  //     {
  //       name: "Coke 330ml Can",
  //       price: "$2.90",
  //     },
  //     {
  //       name: "Coke Diet 330ml Can",
  //       price: "$2.90",
  //     },
  //     {
  //       name: "Coke Zero 330ml Can",
  //       price: "$2.90",
  //     },
  //     {
  //       name: "Coke Bottle",
  //       price: "$4.90",
  //     },
  //     {
  //       name: "Sprite 330ml Can",
  //       price: "$2.90",
  //     },
  //     {
  //       name: "V Blue Can",
  //       price: "$3.90",
  //     },
  //     {
  //       name: "V Green Can",
  //       price: "$3.90",
  //     },
  //     {
  //       name: "OKF Aloe Drink (500ml Bottle) Original",
  //       price: "$3.90",
  //     },

  //     {
  //       name: "Ice Tea - FuzeTea Peach Black Tea",
  //       price: "$4.90",
  //     },

  //     {
  //       name: "Ice Tea - FuzeTea Lemon Green Tea",
  //       price: "$4.90",
  //     },
  //     {
  //       name: "Keri Juice - Apple",
  //       price: "$4.90",
  //     },
  //     {
  //       name: "Keri Juice - Orange",
  //       price: "$4.90",
  //     },
  //     {
  //       name: "Sparkling Water",
  //       price: "$4.90",
  //     },
  //     {
  //       name: "Mini Pump",
  //       price: "$2.90",
  //     },

  //     {
  //       name: "Bundaberg",
  //       price: "$4.90",
  //     },
  //   ],
  // },
];

export default menuData;
